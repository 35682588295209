#root {
  flex: 1 0 auto;
  display: flex !important;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

html,
body {
  font-family: 'Inter', sans-serif;
}

input,
button,
select,
optgroup,
textarea {
  font-family: 'Inter', sans-serif;
  font-size: var(--font-size);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #181818;
  opacity: 1;
  cursor: not-allowed;
}

th {
  font-weight: 500;
  color: #A1A5B7;
}

.mr-1 {
  margin-right: 0.3rem !important;
}

.form-control:focus {
  box-shadow: none;
}

.card {
  border: none;
  border-radius: 5px;
}

html:not([data-theme=dark]) .card {
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);
}

.modal-lg,
.modal-xl {
  max-width: 960px;
}

.card-title {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.btn-dropdown {
  display: inline-block;
}

.dropdown-menu {
  font-size: var(--font-size);
}

.offcanvas-end {
  width: 900px;
}

.form-check {
  margin-top: 8px;
}

button.style-link {
  background: none;
  border: none;
  border-bottom: 1px dotted;
  cursor: pointer !important;
  text-align: left;
  padding: 0;
  color: var(--bs-primary);
  font-weight: 600;
}

button.style-link:hover {
  background: none;
  color: var(--bs-dark);
}

button.style-link:active,
button.style-link:focus {
  color: var(--bs-primary);
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  box-shadow: none;
}

.rdt_TableBody,
.rdt_Table>div {
  background-color: var(--posify-card-bg) !important;
  color: #fff !important;
}

.rdt_Table,
.rdt_ExpanderRow {
  background-color: var(--posify-card-bg) !important;
}

.rdt_TableRow,
.rdt_TableHead {
  border: none !important;
}

.rdt_TableRow {
  border-bottom: 1px dashed var(--posify-border-color) !important;
}

.scroll-table .rdt_TableRow div:last-child {
  justify-content: end;
}

.scroll-table .rdt_TableHeadRow div:last-child>div {
  justify-content: end;
}

.detailBox {
  padding: 25px;
  margin-top: -1px;
  background: var(--posify-card-bg);
  border-radius: 0.475rem !important;
}

.offcanvas-body .mb-3 {
  margin-bottom: 0px !important;
}

input[type=date].form-control {
  background: none;
}

.store-list {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 30px 0;
  justify-content: center;
  flex-wrap: wrap;
}

.store-list .box-store {
  padding: 15px;
  width: 18.6666%;
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow .3s;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 120px;
  line-height: 30px;
}

.store-list .box-store:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .2)
}

.store-list .box-store img {
  max-width: 50px;
  opacity: .6;
  transition: max-width .3s;
}

.btn-addStore {
  font-size: 15px !important;
}

.filter-list {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
}

.filter-list button {
  width: calc(100% / 5 - 5px);
  background: #fff;
  border: 2px solid #fff;
  padding: 15px 5px;
}

.filter-list button:hover {
  border: 2px solid var(--base-color);
}

button.remove-btn {
  border: 0;
  background: 0;
  text-align: center;
  width: 100%;
  opacity: .6;
}

button.remove-btn:hover {
  opacity: 1;
  background: 0;
}

button.remove-btn img {
  width: 20px;
}

.quote {
  color: rgb(151, 150, 150);
  margin: 8px 0;
  display: block;
  font-style: italic;
}

.product-category {
  display: flex;
  list-style: none;
  gap: 8px;
  margin-top: 8px;
  overflow-x: scroll;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: start;
  align-items: flex-start;
}

.product-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.product-list li {
  width: 24%;
}

input[type="file"].custom {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.react-date-time-dark .rdtOpen .rdtPicker {
  background: var(--bs-border-color);
}

.react-date-time-dark .rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  background: var(--posify-card-bg);
}

.rdtPicker {
  background: var(--bs-border-color);
  border: none;
  border-radius: .55rem;
  top: 40px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.rdtPicker th {
  border: none;
}

.rdtPicker td {
  background: var(--bs-border-color);
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--posify-modal-bg);
}

.rdtPicker thead tr:first-of-type th:hover {
  background: var(--posify-card-bg);
  border-radius: .55rem;
}

.rdtPicker tfoot {
  border-top: 0px solid var(--posify-card-bg);
}

.rdtPicker .rdtTimeToggle:hover {
  background: var(--bs-border-color);
}

.rdtCounter .rdtBtn:hover {
  background: var(--posify-card-bg);
  border-radius: .55rem;
}

.rti--container {
  --rti-bg: var(--posify-input-bg) !important;
  --rti-main: var(--posify-page-bg) !important;
  border: 1px solid var(--posify-input-border-color) !important;
  --rti-tag-padding: .25rem .55rem;
  --rti-radius: 0.4rem !important;
  --rti-s: 0px !important;
  --rti-tag: var(--posify-page-bg) !important;
  --rti-tag-remove: #fff !important;
  --rti-tag-padding: .55rem .25rem;
  padding-left: 5px !important;
}

.rti--container:focus-within {
  border: 1px solid var(--posify-input-border-color) !important;
}

.rti--tag {
  margin-right: 3px;
  margin-bottom: 0;
}

.rti--container button {
  margin-left: 5px;
  color: #fff;
}

.rti--input {
  background-color: var(--posify-input-bg) !important;
  border: none !important;
  color: var(--posify-input-color);
  box-shadow: none !important;
  padding: 8px 10px;
}

.account-menu-item {
  cursor: pointer;
}

.account-menu-item>.menu {
  display: block;
  pointer-events: none;
  opacity: 0;
  left: 40px;
  bottom: 20px;
  transition: all ease-in-out 200ms;
}

.account-menu-item.show>.menu {
  display: block;
  bottom: 70px;
  pointer-events: initial;
  opacity: 1;
}

.account-menu-item:after {
  content: '';
  position: absolute;
  width: 65px;
  height: 70px;
  bottom: 15px;
  left: 20px;
  background: transparent;
}

body.swal2-height-auto {
  height: 100% !important;
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
  background: rgba(0, 0, 0, .8);
}

.scroll-table-sm {
  overflow-y: auto !important;
}

.scroll-table-sm .rdt_Table {
  height: 400px;
}

.scroll-table .rdt_Table>div {
  background-color: var(--posify-card-bg) !important;
}

.scroll-table .rdt_Table,
.table-responsive {
  height: 660px;
  overflow-y: auto !important;
}

.react-select-container {
  border-radius: 0.475rem !important;
  width: 100%;
  height: 36px;
}

.react-select__control--is-focused {
  border-color: var(--bs-gray-200) !important;
  box-shadow: none !important;
  height: 36px;
}

.react-select__value-container {
  height: 36px;
}

.react-select__single-value {
  color: var(--bs-gray-700) !important;
}

.react-select__menu {
  top: 33px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background-color: var(--posify-page-bg) !important;
}

.react-select__control {
  background-color: var(--posify-input-bg) !important;
  border: 1px solid var(--posify-input-border-color) !important;
  color: var(--posify-input-color);
  border-radius: 0.475rem !important;
  min-height: 36px !important;
  height: 36px;
}

.react-select__option--is-focused {
  background-color: var(--bs-gray-300) !important;
}

.react-select__option--is-selected {
  background-color: var(--posify-primary) !important;
}

.react-select__indicator-separator {
  background-color: var(--bs-gray-400) !important;
}

.react-select__indicator {
  color: var(--bs-gray-500) !important;
  padding-right: 10px !important;
}

.react-select__input {
  color: var(--bs-gray-500) !important;
}

.react-select__placeholder {
  color: var(--bs-gray-500) !important;
}

.select-group .react-select__control,
.select-group .form-select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0 !important;
}

.select-group button {
  background: var(--posify-input-bg) !important;
  border: 1px solid var(--posify-input-border-color) !important;
  color: var(--posify-input-color);
  border-left-width: 0 !important;
  height: 36px;
  border-top-right-radius: 0.475rem !important;
  border-bottom-right-radius: 0.475rem !important;
}

.select-group button:hover,
.select-group button:focus,
.select-group button:active {
  background: var(--posify-input-bg) !important;
}

.select-group button svg {
  width: 25px;
  padding-right: 8px;
}

.object-fit-cover {
  object-fit: cover;
}

.box {
  width: 80%;
  bottom: 0px;
  z-index: 9;
  background-color: rgba(255,255,255, .8);
  color: #000;
  padding: 15px 15px;
  position: fixed;
  margin: 40px;
  float: left;
  border-radius: 8px;
  text-align: center;
}

.box.arrow-bottom:after {
  content: " ";
  position: absolute;
  right: 45%;
  bottom: -10px;
  border-top: 10px solid rgba(255,255,255, .8);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: none;
}

html .image-input-placeholder {
  background-image: url('../public/img/blank-image.svg');
}

html[data-theme=dark] .image-input-placeholder {
  background-image: url('../public/img/blank-image-dark.svg');
}

html:after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--posify-page-bg);
  opacity: .9;
  z-index: -1;
  top: 0;
  left: 0;
  position: fixed;
}

html[data-theme=dark]:after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--posify-page-bg);
  opacity: .4;
  z-index: -1;
  top: 0;
  left: 0;
  position: fixed;
}

html body {
  background-attachment: fixed;
}

html[data-bg="1"] body {
  background-image: url('../public/pattern/1.png');
  background-size: 300px 650px;
}

html[data-bg="2"] body {
  background-image: url('../public/pattern/2.png');
  background-size: 300px 650px;
}

html[data-bg="3"] body {
  background-image: url('../public/pattern/3.png');
  background-size: 300px 650px;
}

html[data-bg="4"] body {
  background-image: url('../public/pattern/4.png');
  background-size: 300px 650px;
}

html[data-bg="5"] body {
  background-image: url('../public/pattern/5.png');
  background-size: 300px 650px;
}

html[data-bg="6"] body {
  background-image: url('../public/pattern/6.png');
  background-size: 300px 650px;
}

/* swal2 */
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open) {
  overflow-y: initial !important;
}

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.sweetalert2-nopadding),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open):not(.sweetalert2-nopadding) {
  padding-right: 0 !important;
}

.swal2-popup {
  background-color: var(--bs-body-bg);
  padding: 2rem;
  border-radius: 0.475rem;
}

.swal2-popup .swal2-title {
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--bs-dark);
}

.swal2-popup .swal2-html-container,
.swal2-popup .swal2-content {
  font-weight: normal;
  font-size: 1.1rem;
  color: var(--bs-gray-800);
}

.swal2-popup .btn {
  margin: 15px 5px 0;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none;
}

.swal2-popup .swal2-actions {
  margin: 1.5rem auto 1rem auto;
}

.swal2-popup .swal2-actions button {
  padding: 10px 25px;
}

.swal2-container {
  overflow-y: hidden !important;
}

.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.2);
}

body.swal2-height-auto {
  height: 100% !important;
}

.swal2-icon.swal2-warning {
  border-color: var(--bs-warning);
  color: var(--bs-warning);
}

.swal2-icon.swal2-error {
  border-color: var(--bs-danger);
  color: var(--bs-danger);
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  background-color: rgba(var(--bs-danger-rgb), 0.75);
}

.swal2-icon.swal2-success {
  border-color: var(--bs-success);
  color: var(--bs-success);
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: var(--bs-success);
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(var(--bs-success-rgb), 0.3);
}

.swal2-icon.swal2-info {
  border-color: var(--bs-info);
  color: var(--bs-info);
}

.swal2-icon.swal2-question {
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}

.swal2-input {
  text-align: center;
}

.apexcharts-canvas {
  position: relative;
  user-select: none;
}

.apexcharts-canvas ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

.apexcharts-canvas ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.apexcharts-inner {
  position: relative;
}

.apexcharts-text tspan {
  font-family: inherit;
}

.legend-mouseover-inactive {
  transition: 0.15s ease all;
  opacity: 0.2;
}

.apexcharts-series-collapsed {
  opacity: 0;
}

.apexcharts-tooltip {
  border-radius: 5px;
  box-shadow: 2px 2px 6px -4px #999;
  cursor: default;
  font-size: 14px;
  left: 62px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;
}

.apexcharts-tooltip.apexcharts-active {
  opacity: 1;
  transition: 0.15s ease all;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 0.96);
}

.apexcharts-tooltip.apexcharts-theme-dark {
  color: #fff;
  background: rgba(30, 30, 30, 0.8);
}

.apexcharts-tooltip * {
  font-family: inherit;
}

.apexcharts-tooltip-title {
  padding: 6px;
  font-size: 15px;
  margin-bottom: 4px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #eceff1;
  border-bottom: 1px solid #ddd;
}

.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
  background: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid #333;
}

.apexcharts-tooltip-text-goals-value,
.apexcharts-tooltip-text-y-value,
.apexcharts-tooltip-text-z-value {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
}

.apexcharts-tooltip-text-goals-label:empty,
.apexcharts-tooltip-text-goals-value:empty,
.apexcharts-tooltip-text-y-label:empty,
.apexcharts-tooltip-text-y-value:empty,
.apexcharts-tooltip-text-z-value:empty,
.apexcharts-tooltip-title:empty {
  display: none;
}

.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
  padding: 6px 0 5px;
}

.apexcharts-tooltip-goals-group,
.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
  display: flex;
}

.apexcharts-tooltip-text-goals-label:not(:empty),
.apexcharts-tooltip-text-goals-value:not(:empty) {
  margin-top: -6px;
}

.apexcharts-tooltip-marker {
  width: 12px;
  height: 12px;
  position: relative;
  top: 0;
  margin-right: 10px;
  border-radius: 50%;
}

.apexcharts-tooltip-series-group {
  padding: 0 10px;
  display: none;
  text-align: left;
  justify-content: left;
  align-items: center;
}

.apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker {
  opacity: 1;
}

.apexcharts-tooltip-series-group.apexcharts-active,
.apexcharts-tooltip-series-group:last-child {
  padding-bottom: 4px;
}

.apexcharts-tooltip-series-group-hidden {
  opacity: 0;
  height: 0;
  line-height: 0;
  padding: 0 !important;
}

.apexcharts-tooltip-y-group {
  padding: 6px 0 5px;
}

.apexcharts-custom-tooltip,
.apexcharts-tooltip-box {
  padding: 4px 8px;
}

.apexcharts-tooltip-boxPlot {
  display: flex;
  flex-direction: column-reverse;
}

.apexcharts-tooltip-box>div {
  margin: 4px 0;
}

.apexcharts-tooltip-box span.value {
  font-weight: 700;
}

.apexcharts-tooltip-rangebar {
  padding: 5px 8px;
}

.apexcharts-tooltip-rangebar .category {
  font-weight: 600;
  color: #777;
}

.apexcharts-tooltip-rangebar .series-name {
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  opacity: 0;
  pointer-events: none;
  color: #373d3f;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  background: #eceff1;
  border: 1px solid #90a4ae;
}

.apexcharts-xaxistooltip {
  padding: 9px 10px;
  transition: 0.15s ease all;
}

.apexcharts-xaxistooltip.apexcharts-theme-dark {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #fff;
}

.apexcharts-xaxistooltip:after,
.apexcharts-xaxistooltip:before {
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.apexcharts-xaxistooltip:after {
  border-color: transparent;
  border-width: 6px;
  margin-left: -6px;
}

.apexcharts-xaxistooltip:before {
  border-color: transparent;
  border-width: 7px;
  margin-left: -7px;
}

.apexcharts-xaxistooltip-bottom:after,
.apexcharts-xaxistooltip-bottom:before {
  bottom: 100%;
}

.apexcharts-xaxistooltip-top:after,
.apexcharts-xaxistooltip-top:before {
  top: 100%;
}

.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #eceff1;
}

.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #90a4ae;
}

.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after,
.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
  border-bottom-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-xaxistooltip-top:after {
  border-top-color: #eceff1;
}

.apexcharts-xaxistooltip-top:before {
  border-top-color: #90a4ae;
}

.apexcharts-xaxistooltip-top.apexcharts-theme-dark:after,
.apexcharts-xaxistooltip-top.apexcharts-theme-dark:before {
  border-top-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-xaxistooltip.apexcharts-active {
  opacity: 1;
  transition: 0.15s ease all;
}

.apexcharts-yaxistooltip {
  padding: 4px 10px;
}

.apexcharts-yaxistooltip.apexcharts-theme-dark {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #fff;
}

.apexcharts-yaxistooltip:after,
.apexcharts-yaxistooltip:before {
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.apexcharts-yaxistooltip:after {
  border-color: transparent;
  border-width: 6px;
  margin-top: -6px;
}

.apexcharts-yaxistooltip:before {
  border-color: transparent;
  border-width: 7px;
  margin-top: -7px;
}

.apexcharts-yaxistooltip-left:after,
.apexcharts-yaxistooltip-left:before {
  left: 100%;
}

.apexcharts-yaxistooltip-right:after,
.apexcharts-yaxistooltip-right:before {
  right: 100%;
}

.apexcharts-yaxistooltip-left:after {
  border-left-color: #eceff1;
}

.apexcharts-yaxistooltip-left:before {
  border-left-color: #90a4ae;
}

.apexcharts-yaxistooltip-left.apexcharts-theme-dark:after,
.apexcharts-yaxistooltip-left.apexcharts-theme-dark:before {
  border-left-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-yaxistooltip-right:after {
  border-right-color: #eceff1;
}

.apexcharts-yaxistooltip-right:before {
  border-right-color: #90a4ae;
}

.apexcharts-yaxistooltip-right.apexcharts-theme-dark:after,
.apexcharts-yaxistooltip-right.apexcharts-theme-dark:before {
  border-right-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-yaxistooltip.apexcharts-active {
  opacity: 1;
}

.apexcharts-yaxistooltip-hidden {
  display: none;
}

.apexcharts-xcrosshairs,
.apexcharts-ycrosshairs {
  pointer-events: none;
  opacity: 0;
  transition: 0.15s ease all;
}

.apexcharts-xcrosshairs.apexcharts-active,
.apexcharts-ycrosshairs.apexcharts-active {
  opacity: 1;
  transition: 0.15s ease all;
}

.apexcharts-ycrosshairs-hidden {
  opacity: 0;
}

.apexcharts-selection-rect {
  cursor: move;
}

.svg_select_boundingRect,
.svg_select_points_rot {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.apexcharts-selection-rect+g .svg_select_boundingRect,
.apexcharts-selection-rect+g .svg_select_points_rot {
  opacity: 0;
  visibility: hidden;
}

.apexcharts-selection-rect+g .svg_select_points_l,
.apexcharts-selection-rect+g .svg_select_points_r {
  cursor: ew-resize;
  opacity: 1;
  visibility: visible;
}

.svg_select_points {
  fill: #efefef;
  stroke: #333;
  rx: 2;
}

@media screen and (max-width: 768px) {
  .store-list .box-store {
    width: 100%;
    height: 80px;
  }
}

.apexcharts-svg.apexcharts-zoomable.hovering-zoom {
  cursor: crosshair;
}

.apexcharts-svg.apexcharts-zoomable.hovering-pan {
  cursor: move;
}

.apexcharts-menu-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon,
.apexcharts-selection-icon,
.apexcharts-toolbar-custom-icon,
.apexcharts-zoom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 24px;
  color: #6e8192;
  text-align: center;
}

.apexcharts-menu-icon svg,
.apexcharts-reset-icon svg,
.apexcharts-zoom-icon svg,
.apexcharts-zoomin-icon svg,
.apexcharts-zoomout-icon svg {
  fill: #6e8192;
}

.apexcharts-selection-icon svg {
  fill: #444;
  transform: scale(0.76);
}

.apexcharts-theme-dark .apexcharts-menu-icon svg,
.apexcharts-theme-dark .apexcharts-pan-icon svg,
.apexcharts-theme-dark .apexcharts-reset-icon svg,
.apexcharts-theme-dark .apexcharts-selection-icon svg,
.apexcharts-theme-dark .apexcharts-toolbar-custom-icon svg,
.apexcharts-theme-dark .apexcharts-zoom-icon svg,
.apexcharts-theme-dark .apexcharts-zoomin-icon svg,
.apexcharts-theme-dark .apexcharts-zoomout-icon svg {
  fill: #f3f4f5;
}

.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg {
  fill: #008ffb;
}

.apexcharts-theme-light .apexcharts-menu-icon:hover svg,
.apexcharts-theme-light .apexcharts-reset-icon:hover svg,
.apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg {
  fill: #333;
}

.apexcharts-menu-icon,
.apexcharts-selection-icon {
  position: relative;
}

.apexcharts-reset-icon {
  margin-left: 5px;
}

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  transform: scale(0.85);
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  transform: scale(0.7);
}

.apexcharts-zoomout-icon {
  margin-right: 3px;
}

.apexcharts-pan-icon {
  transform: scale(0.62);
  position: relative;
  left: 1px;
  top: 0;
}

.apexcharts-pan-icon svg {
  fill: #fff;
  stroke: #6e8192;
  stroke-width: 2;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: #008ffb;
}

.apexcharts-pan-icon:not(.apexcharts-selected):hover svg {
  stroke: #333;
}

.apexcharts-toolbar {
  position: absolute;
  z-index: 11;
  max-width: 176px;
  text-align: right;
  border-radius: 3px;
  padding: 0 6px 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apexcharts-menu {
  background: #fff;
  position: absolute;
  top: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 3px;
  right: 10px;
  opacity: 0;
  min-width: 110px;
  transition: 0.15s ease all;
  pointer-events: none;
}

.apexcharts-menu.apexcharts-menu-open {
  opacity: 1;
  pointer-events: all;
  transition: 0.15s ease all;
}

.apexcharts-menu-item {
  padding: 6px 7px;
  font-size: 12px;
  cursor: pointer;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: #eee;
}

.apexcharts-theme-dark .apexcharts-menu {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}

@media screen and (min-width: 768px) {
  .apexcharts-canvas:hover .apexcharts-toolbar {
    opacity: 1;
  }
}

.apexcharts-canvas .apexcharts-element-hidden,
.apexcharts-datalabel.apexcharts-element-hidden,
.apexcharts-hide .apexcharts-series-points {
  opacity: 0;
}

.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value,
.apexcharts-datalabels,
.apexcharts-pie-label {
  cursor: default;
  pointer-events: none;
}

.apexcharts-pie-label-delay {
  opacity: 0;
  animation-name: opaque;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

.apexcharts-annotation-rect,
.apexcharts-area-series .apexcharts-area,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-gridline,
.apexcharts-line,
.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-point-annotation-label,
.apexcharts-radar-series path,
.apexcharts-radar-series polygon,
.apexcharts-toolbar svg,
.apexcharts-tooltip .apexcharts-marker,
.apexcharts-xaxis-annotation-label,
.apexcharts-yaxis-annotation-label,
.apexcharts-zoom-rect {
  pointer-events: none;
}

.apexcharts-marker {
  transition: 0.15s ease all;
}

.block-ui-overlay {
  opacity: 0.9;
  background-color: var(--posify-card-bg);
}

.block-ui-message {
  color: var(--text-dark);
}

.modal-toolbar {
  padding: 6px 8px;
}